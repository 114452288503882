import React from "react";
import CardPromociones from "./card_promociones";



  
const ListaPromociones = ({data}) => {
 console.log(data)

const printCardsIndividual=(data)=>{
    console.log(data)
    var arreglo=[]
    for (let index = 0; index < data.length; index++) {
            arreglo.push(
                
                
                <CardPromociones data={data[index]} />

            );
        

}
    return arreglo;
}



  return (
    
   
                 <div className="mt-xl-5 pt-xl-3 mt-md-5 mt-2">
                    {printCardsIndividual(data)}
                 </div>
                 
  );
};

export default ListaPromociones;